<template>
  <div class="preference-type">
    <div>
      <div v-if="!validValues?.length">
        <span>
          No values found.
        </span>
      </div>
      <div
        v-else
        class="preference-type-dictionary__items"
      >
        <table>
          <thead
            class="preference-type-dictionary__table-title"
          >
            <th>
              Key
            </th>
            <th>
              Value
            </th>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in validValues"
              :id="'tr' + index"
              :ref="el => { if (el) vForDivs[i] = el }"
              :key="index"
              class="preference-type-dictionary__input_item"
              data-arrow-navigable-item
            >
              <td>
                <textarea
                  :id="`keyInput${index}`"
                  v-model="item.key"
                  rows="1"
                  disabled
                  class="preference-type-dictionary__input_item_key"
                />
              </td>
              <td
                class="preference-type-dictionary__input_item_value_td"
              >
                <div
                  v-if="validBaseType === 'Integer'
                    || validBaseType === 'Decimal'
                    || validBaseType === 'Double'"
                  class="preference-type-dictionary__input_item_value"
                >
                  <input
                    :id="'valueInput' + index"
                    :value="displayAsNumber(item.value)"
                    placeholder="Type value..."
                    type="number"
                    disabled
                    @input="item.value = stringFromNumber($event.target.value)"
                  >
                </div>
                <div
                  v-else-if="validBaseType === 'Boolean'"
                  class="preference-type-dictionary__input_item_value"
                >
                  <checkbox
                    v-model="item.value"
                    :enabled="false"
                  />
                </div>
                <div
                  v-else
                >
                  <template
                    v-if="customEditor === 'RtfEditor'"
                  >
                    <RtfViewer
                      :name="`${preferenceName} (${item.key})`"
                      :rtf="item.value"
                    />
                  </template>
                  <template
                    v-else
                  >
                    <textarea
                      :id="'valueInput' + index"
                      :ref="el => setIsTextLongArray(el, index)"
                      v-model="item.value"
                      placeholder="Type value..."
                      class="preference-type-dictionary__input_item_value_textarea"
                      type="text"
                      :rows="isTextLong[index] ? 2 : 1"
                      disabled
                      @focus="onTextareaFocus(index)"
                    />
                    <div
                      v-if="isTextLong[index]"
                      class="preference-type-dictionary__input_item_show_buttons"
                    >
                      <span
                        v-if="!isTextareaOpen[index]"
                        @click="onShowMoreClicked(index)"
                      >
                        Show more
                      </span>
                      <span
                        v-else-if="isTextareaOpen[index]"
                        @click="onShowLessClicked(index)"
                      >
                        Show less
                      </span>
                    </div>
                  </template>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import {
  computed, watch, reactive, ref, nextTick, onMounted,
} from 'vue';

import Checkbox from '@/components/shared/checkbox.vue';
import RtfViewer from '@/components/shared/rtf-viewer.vue';

import { useUtilsStore } from '@/store/utils';

export default {
  components: {
    Checkbox,
    RtfViewer,
  },

  props: {
    preferenceValueDTO: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    console.log('Init type dict');

    const { toHash } = useUtilsStore();

    const vForDivs = ref([]);
    const newItemAdded = ref(false);

    const validValues = computed(() => props.preferenceValueDTO.values);
    const validBaseType = computed(() => props.preferenceValueDTO.baseType);
    const customEditor = computed(() => props.preferenceValueDTO.customEditor);
    const preferenceName = computed(() => props.preferenceValueDTO.name);

    const displayAsNumber = (value) => Number(value);
    const displayAsBoolean = (value) => value === '1';
    const stringFromNumber = (value) => value.toString();
    const stringFromBoolean = (value) => (value ? '1' : '0');

    const isTextLong = reactive([]);

    const isTextareaOpen = reactive([]);
    const twoRowsHeight = 42;

    const setIsTextLongArray = (el, index) => {
      isTextLong[index] = el?.scrollHeight > twoRowsHeight;
    };

    const setHeight = (index, type) => {
      const element = document.getElementById(`${type}Input${index}`);
      element.style.height = 0;
      element.style.height = `${element.scrollHeight}px`;
    };

    const onTextareaFocus = (index) => {
      const inputElement = document.getElementById(`valueInput${index}`);
      const trElement = document.getElementById(`tr${index}`);
      if (inputElement?.scrollHeight > twoRowsHeight) {
        inputElement.style.height = `${inputElement.scrollHeight}px`;
        isTextareaOpen[index] = true;
        trElement.style.verticalAlign = 'top';
      }
    };

    const onShowMoreClicked = (index) => {
      onTextareaFocus(index);
    };

    const onShowLessClicked = (index) => {
      const inputElement = document.getElementById(`valueInput${index}`);
      const trElement = document.getElementById(`tr${index}`);
      inputElement.style.height = `${twoRowsHeight / 16}rem`;
      isTextareaOpen[index] = false;
      trElement.style.verticalAlign = 'inherit';
    };

    onMounted(() => {
      validValues.value.forEach((el, index) => setHeight(index, 'key'));
    });

    watch(() => validValues.value, async () => {
      console.log(vForDivs.value);
      if (newItemAdded.value) {
        newItemAdded.value = false;
        await nextTick();
        vForDivs.value.undefined.firstChild.focus();
      }
    },
    { deep: true });

    return {
      displayAsNumber,
      displayAsBoolean,
      stringFromNumber,
      stringFromBoolean,
      vForDivs,
      validValues,
      validBaseType,
      customEditor,
      preferenceName,
      toHash,
      onTextareaFocus,
      setIsTextLongArray,
      isTextLong,
      isTextareaOpen,
      onShowMoreClicked,
      onShowLessClicked,
    };
  },
};
</script>

<style scoped>
table {
  border-collapse: collapse;
}

.preference-type-dictionary__table-title {
  font-size: 0.75rem;
  font-weight: normal;
  border-bottom: 0.0625rem solid var(--colour-border-light);
}

.preference-type-dictionary__table-title > th {
  font-size: 0.75rem;
  font-weight: normal;
}

.preference-type-dictionary__items {
  padding-bottom: 1rem;
}

.preference-type-dictionary__input_item {
  border-bottom: 0.0625rem solid var(--colour-border-light);
}

.preference-type-dictionary__input_item_key {
  max-width: 10rem;
  padding: 0;
  font-weight: bold;
  color: var(--colour-text-dark);
  resize: none;
  background-color: var(--colour-bg-default);
  border: 0;
}

.preference-type-dictionary__input_item_value_textarea {
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
  color: var(--colour-text-dark);
  resize: none;
  background-color: var(--colour-bg-default);
  border: 0;
}

.preference-type-dictionary__input_item_value_td {
  width: 100%;
  vertical-align: inherit;
}

.preference-type-dictionary__input_item > td {
  padding: 0.75rem 0;
  vertical-align: inherit;
}

.preference-type-dictionary__input_item_show_buttons {
  padding-top: 0.5rem;
  text-decoration: underline;
}

.preference-type-dictionary__input_item_show_buttons:hover {
  color: var(--colour-text-dark);
  cursor: pointer;
}

@media (min-width: 46.375rem) {
  .preference-type-dictionary__input_item_key {
    min-width: 20rem;
  }
}
</style>
